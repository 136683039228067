export const NavbarData = [
    {
        Route: "/",
        Cname: "nav-link",
        Id: 0,
        Text: "Home"
    },
    {
        Route: "/About",
        Cname: "nav-link",
        Id: 1,
        Text: "About"
    },
    {
        Route: "/Projects",
        Cname: "nav-link",
        Id: 2,
        Text: "Projects"
    },
    {
        Route: "/Contact",
        Cname: "nav-link",
        Id: 3,
        Text: "Contact"
    },
]
